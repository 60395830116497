import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import "@hotwired/turbo-rails"

// Rails
import Rails from "@rails/ujs"
Rails.start()

import "trix"
import "@rails/actiontext"

import LocalTime from "local-time"
LocalTime.start()
window.LocalTime = LocalTime

import "chartkick/chart.js"

// Polyfills
import "element-closest"
import "form-request-submit-polyfill" // apparently Safari does not support requestSubmit() on forms
import "./patches/safari-ios-back-btn"

// import "bootstrap"
import "bootstrap/js/dist/alert"
import "bootstrap/js/dist/button"
// import "bootstrap/js/dist/carousel"
import "bootstrap/js/dist/collapse"
import "bootstrap/js/dist/offcanvas"
import "bootstrap/js/dist/dropdown"
import "bootstrap/js/dist/modal"
// import "bootstrap/js/dist/popover"
// import "bootstrap/js/dist/scrollspy"
import "bootstrap/js/dist/tab"
// import "bootstrap/js/dist/toast"
// import "bootstrap/js/dist/tooltip"

// Setup Stimulus
import "./controllers"

// App Specific
import "./bootstrap/remote_flash_messages"

import "./activestorage/direct-upload"
import "./network-status"
import "./stripe-setup"

// Google Maps async loading callback
window.initMap = function (...args) {
  const event = document.createEvent("Events")
  event.initEvent("google-maps-callback", true, true)
  event.args = args
  window.dispatchEvent(event)
}

// protect ActionText upload types
// https://blog.saeloun.com/2019/11/12/attachments-in-action-text-rails-6/
window.addEventListener("trix-file-accept", function (event) {
  const acceptedTypes = ["image/jpeg", "image/png", "image/gif", "application/pdf"]
  if (!acceptedTypes.includes(event.file.type)) {
    event.preventDefault()
    alert("Only image files and PDFs can be included as attachments")
  }
})
